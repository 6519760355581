<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Módulo Fiscal</v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text v-if="loading" class="text-center my-5">
                        <h1>Aguarde, carregando informações...</h1>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <div class="my-6" style="text-align: center; line-height: 30px;">
                            <h1>Conheça o Módulo Fiscal (NFC-e)</h1>
                        </div>

                        <v-row class="mt-4" justify="center">
                            <v-col cols="12" sm="6" md="4">
                                <v-card class="pa-4 highlighted-card fiscal-card" elevation="10">
                                    <v-card-title class="white--text justify-center">
                                        Módulo Fiscal
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1">
                                        <p>Gere notas fiscais com apenas um clique.</p>
                                        <p>Você escolhe os pedidos que irá gerar nota.</p>
                                        <p>Imprima a nota fiscal com facilidade.</p>
                                        <p>Envio do relatório de XML automático para sua contabilidade.</p>
                                        <p>Deixe a configuração fiscal por nossa conta.</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <div class="mt-12 mb-8 mx-2 text-h5">Ficou interessado e gostaria de saber mais? Clique no botão abaixo e converse conosco pelo whatsapp 👇🏻</div>

                        <v-btn @click="queroSaberMais" color="success" x-large block>
                            <v-icon>mdi-whatsapp</v-icon>
                            Quero Saber Mais
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'ModuloFiscal',

    components: {
        BaseContainer,
    },

    data: () => ({
        loading: true,
    }),

    mounted() {
        this.consultar();
    },

    methods: {
        consultar() {
            this.loading = true;

            this.$http.post('assinaturas/detalhes').then(() => {
                this.pageView();
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },

        pageView() {
            this.$http.post('logger?type=modulo-fiscal');
        },

        queroSaberMais() {
            this.$http.post('logger?type=modulo-fiscal-interesse');

            const msg = encodeURIComponent('Olá, quero SABER MAIS sobre o Módulo Fiscal');
            this.goToWhatsapp(msg);
        },

        goToWhatsapp(msg) {
            const isMobile = !this.$vuetify.breakpoint.smAndUp;

            const link = isMobile ? 'whatsapp://send' : 'https://api.whatsapp.com/send';
            const phone = '5549988951210';

            window.open(`${link}?phone=${phone}&text=${msg}`);
        }
    },
}
</script>

<style scoped>
p {
    margin-bottom: 10px;
}

.highlighted-card:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.fiscal-card {
    background: linear-gradient(to right, #52bf54, #81d98f);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}
</style>